.unsubscribe-confirm {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .unsubscribe-confirm-whitelabel-container {
    background: var(--main-navy-light);
  }
  .unsubscribe-confirm-loader-container {
    height: 100%;
    background: var(--main-navy-extra-light);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-navy-extra-dark);
  }
  .unsubscribe-confirm-loader-container h2 {
    margin: 0;
  }
  .unsubscribe-confirm-loader-container .unsubscribe-confirm-loader {
    height: 150px;
  }
  .unsubscribe-confirm img {
    margin-bottom: 30px;
    width: 100px;
  }
  .unsubscribe-confirm-card {
    padding: 50px 70px 20px;
    background-color: #fff;
    border-color: var(--main-navy);
    border-radius: 6px;
    color: var(--main-navy-dark);
    text-align: center;
    max-width: 650px;
    width: 650px;
  }
  @media screen and (max-width: 415px) {
    .unsubscribe-confirm-card {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .unsubscribe-confirm h1 {
    margin-top: 0;
    font-size: var(--font-size-large);
    color: var(--main-navy-extra-dark);
  }
  .unsubscribe-confirm strong {
    font-family: var(--font-family-bold);
    color: var(--main-navy-extra-dark);
  }
  .unsubscribe-confirm-reasons {
    margin: 30px 0;
  }
  .unsubscribe-confirm-reasons button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .unsubscribe-confirm-reasons button .emoji-mart-emoji {
    padding-right: 5px;
  }
  .subscription-confirm-input-container {
    display: inline-block;
    width: 100%;
  }
  .subscription-confirm-input-email {
    margin-right: 15px;
    width: 70%;
  }
  .subscription-confirm-input-email.subscription-confirm-input-gdpr {
    margin-bottom: 15px;
    margin-right: 0;
  }
  @media screen and (max-width: 415px) {
    .subscription-confirm-input-email {
      margin-bottom: 15px;
      margin-right: 0;
      width: 100%;
    }
  }
  .subscription-confirm-input-email.raw-input {
    display: inline-block;
  }
  .subscription-confirm-input-container .subscription-confirm-button {
    width: 15%;
  }
  .subscription-confirm-input-container .subscription-confirm-button.subscription-confirm-button-gdpr {
    width: 70%;
    margin-bottom: 70px;
  }
  @media screen and (max-width: 415px) {
    .subscription-confirm-input-container .subscription-confirm-button {
      width: 100%;
    }
  }
  .subscription-confirm-error-message {
    color: var(--utility-red-light);
  }
  .subscription-radio-choices {
    margin-top: 20px;
  }
  .subscription-radio-choice {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 8px auto;
    border: 1px solid var(--main-navy);
    border-radius: 3px;
    padding: 5px;
    background: var(--main-navy-extra-light);
    cursor: pointer;
  }
  .subscription-radio-choice:hover {
    border-color: var(--main-navy-dark);
  }
  .subscription-radio-choice > label {
    color: var(--main-navy-extra-dark);
    cursor: pointer;
  }
  .subscription-radio-choice > .subscription-radio-input > input[type="radio"] {
    font-size: 18px;
  }
  .subscription-radio-choice-selected {
    background: #fff;
    border-color: var(--main-green);
  }
  .unsubscribe-responded {
    position: relative;
  }
  .unsubscribe-responded svg.tide-icon {
    height: 100%;
    width: 80px;
  }
  .unsubscribe-responded-gdpr {
    height: 257px;
  }
  .unsubscribe-responded-not-gdpr {
    height: 285px;
  }
  .unsubscribe-responded svg.tide-icon path {
    fill: var(--main-green);
  }
  .subscription-update-button {
    width: 70%;
    margin-bottom: 70px;
  }
  